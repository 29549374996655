.text-center{
text-align:center;
}

.lg-view{
   display:inline-block;
}

.sm-view{
   display:none;
}

@media screen and (max-width: 500px) {
    .lg-view{
       display:none;
    }

    .sm-view{
       display:inline-block;
    }
}
